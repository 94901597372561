import React, { useContext, useEffect, useState } from "react";
import { BsFire } from "react-icons/bs";
import { TiSortAlphabetically, TiThLarge } from "react-icons/ti";
import { BsAlarmFill, BsFillCalendarDateFill } from "react-icons/bs";
import { GoCalendar } from "react-icons/go";
import { GiTrophyCup } from "react-icons/gi";
import { AiFillDatabase } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";

const GameSidebar = ({
  sideDataType,
  keyTime,
  setKeyTime,
  homePlayType,
  setHomePlayType,
}) => {
  const { setParly } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <>
      <aside>
        {sideDataType !== "sportData" ? (
          <ul className="p-0 m-0 sideicon">
            {sideDataType !== "tableData" && (
              <li
                onClick={() => setHomePlayType("catalog")}
                className={homePlayType == "catalog" ? "active" : ""}
              >
                <i class="icon-catalog"></i>
                <span>Catalog</span>
              </li>
            )}

            <li
              onClick={() => setHomePlayType("latest")}
              className={homePlayType == "latest" ? "active" : ""}
            >
            <i class="icon-latest"></i>
              <span>Latest</span>
            </li>
            <li
              onClick={() => setHomePlayType("az")}
              className={homePlayType == "az" ? "active" : ""}
            >
            <i class="icon-a-z"></i>
              <span>A-Z</span>
            </li>
          </ul>
        ) : (
          <ul className="p-0 m-0 sideicon" >
            <li
              className={keyTime === "in_play" ? "active" : ""}
              onClick={() => {
                setKeyTime("in_play");
                setParly(false);
              }}
            >
                  <i class="icon-inplay-b"></i>
              
              <span>In Play</span>
            </li>

            <li
              className={keyTime === "today" ? "active" : ""}
              onClick={() => {
                setKeyTime("today");
                setParly(false);
              }}
            >
               <i class="icon-today"></i>
              
              <span>Today</span>
            </li>
            <li
              className={keyTime === "tomorrow" ? "active" : ""}
              onClick={() => {
                setKeyTime("tomorrow");
                setParly(false);
              }}
            >
                <i class="icon-tomorrow"></i>
        
              <span>Tomorrow</span>
            </li>

            <li onClick={() => navigate("/leagues")}>
            <i class="icon-trophy"></i>
            
              <span>Leagues</span>
            </li>
            <li
              onClick={() => {
                setParly(true);
                navigate("/sports");
              }}
            > <i class="icon-parlay"></i>
             
              <span>Parlay</span>
            </li>
          </ul>
        )}
      </aside>
    </>
  );
};

export default GameSidebar;
