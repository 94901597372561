import React, { useContext, useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { IoChevronBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import LayoutComponent from "../components/shared/Layout";
import { IoIosArrowBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { preventMaxInput, validationRules } from "../Utils/constants";
import { isEmpty, pick } from "lodash";
import jwt_decode from "jwt-decode";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
const ChangePasswordProfile = () => {
  const [showPassword, setShowPassword] = useState({
    first: true,
    second: true,
    third: true,
  });
  const { logoutUser, user, profileData } =useContext(AuthContext);
  const navigate = useNavigate();

  const handleShow = (id) => {
    setShowPassword({ ...showPassword, [id]: !showPassword[id] });
  };
  const [step, setStep] = useState(0);
  const [success, setSuccess] = useState({
    check: true,
    message: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    trigger,
    setValue,
  } = useForm({});



  useEffect(() => {
    if (!isEmpty(watch("confirmPassword"))) {
      if (watch("newPassword")) {
        trigger("confirmPassword");
      }
    }
  }, [watch("newPassword")]);

  const changePassword = async (body) => {
    body.uniqueId = Math.random() * 10000;
    const obj = pick(body, ["oldPassword", "newPassword", "uniqueId"]);
    const { status, data } = await apiPost(apiPath.changePassword, obj);
    if (status === 200) {
      if (data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.setItem("token", data.results.token);
        localStorage.setItem("refresh_token", data.results.refresh_token);
        navigate("/");
      } else {
        setSuccess({ check: true, message: data?.message });
      }
    } else {
      setSuccess({ check: true, message: data?.message });
    }
  };

  return (
    <div>
  
        <LayoutComponent page={"change"}>
          <div className="d-flex align-items-center justify-content-start">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                background: "white",
                borderRadius: "50%",
                marginLeft: "8px",
                marginTop: "8px",
              }}
              onClick={() => {
                logoutUser();
              }}
            >
              <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
            </div>
          </div>
          <div className="login-log-sec w-100">
            <div className="login-logo">
              <img src="../assets/images/velki-logo.png" alt="" />
            </div>
            <div className="px-3">
              <img
                src="../assets/images/velki-login-signup-banner.png"
                alt=""
              />
            </div>
            <Form
             onSubmit={handleSubmit(changePassword)}
              className="bg-white p-4"
            >
              <label className="mb-3 d-block text-center fs-2">
                Change Password
              </label>

              <Form.Group className="mb-3">
                <FloatingLabel
                  controlId="floatingPassword"
                  label="New Password"
                >
                  <Form.Control
                    type={!showPassword?.second ? "text" : "password"}
                    placeholder="New Password"
                    maxLength={16}
                    minLength={8}
                    onInput={(e) => preventMaxInput(e)}
                    {...register("newPassword", {
                      required: "Please enter new password.",
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        if (!!value.trim()) {
                          return true;
                        } else {
                          return "White spaces not allowed.";
                        }
                      },
                      // pattern: {
                      //   value: validationRules.password,
                      //   message:
                      //     "New Password must contain lowercase,uppercase characters, numbers, special character and must be 8 character long.",
                      // },
                    })}
                  />
                  <FaLock className="position-absolute translate-middle-y top-50" />
                  <Button
                    className="position-absolute translate-middle-y top-50 end-0 me-3 bg-white border-0 text-dark p-0 "
                    onClick={() => handleShow("second")}
                  >
                    {!showPassword?.second ? (
                      <FaEye className="opacity-100 fs-4" />
                    ) : (
                      <FaEyeSlash className="opacity-100 fs-4" />
                    )}
                  </Button>
                </FloatingLabel>
                {errors.newPassword && errors?.newPassword?.message && (
                  <label className="text-danger">
                    {errors.newPassword.message}
                  </label>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel
                  controlId="floatingPassword"
                  label="New Password Confirm"
                >
                  <Form.Control
                    type={!showPassword?.third ? "text" : "password"}
                    placeholder="New Password Confirm"
                    onInput={(e) => preventMaxInput(e)}
                    maxLength={16}
                    minLength={8}
                    {...register("confirmPassword", {
                      required: {
                        value: true,
                        message: "Please enter new password confirm.",
                      },
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        if (!isEmpty(watch("newPassword"))) {
                          if (value == watch("newPassword")) {
                            return true;
                          } else {
                            return "Password and confirm password does not match.";
                          }
                        }
                      },
                    })}
                    onChange={(e) => {
                      setValue("confirmPassword", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <FaLock className="position-absolute translate-middle-y top-50" />
                  <Button
                    className="position-absolute translate-middle-y top-50 end-0 me-3 bg-white border-0 text-dark p-0 "
                    onClick={() => handleShow("third")}
                  >
                    {!showPassword?.third ? (
                      <FaEye className="opacity-100 fs-4" />
                    ) : (
                      <FaEyeSlash className="opacity-100 fs-4" />
                    )}
                  </Button>
                </FloatingLabel>
                {errors.confirmPassword && errors?.confirmPassword?.message && (
                  <label className="text-danger">
                    {errors.confirmPassword.message}
                  </label>
                )}
              </Form.Group>
              <Form.Group className="mb-1">
                <FloatingLabel
                  controlId="floatingPassword"
                  label="Your Password"
                >
                  <Form.Control
                    type={!showPassword?.first ? "text" : "password"}
                    placeholder="Your Password"
                    maxLength={16}
                    minLength={8}
                    onInput={(e) => preventMaxInput(e)}
                    {...register("oldPassword", {
                      required: "Please enter your password.",
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        if (!!value.trim()) {
                          return true;
                        } else {
                          return "White space are not allowed";
                        }
                      },
                    })}
                  />
                  <FaLock className="position-absolute translate-middle-y top-50" />
                  <Button
                    className="position-absolute translate-middle-y top-50 end-0 me-3 bg-white border-0 text-dark p-0 "
                    onClick={() => handleShow("first")}
                  >
                    {!showPassword?.first ? (
                      <FaEye className="opacity-100 fs-4" />
                    ) : (
                      <FaEyeSlash className="opacity-100 fs-4" />
                    )}
                  </Button>
                </FloatingLabel>
                {errors.oldPassword && errors?.oldPassword?.message && (
                  <label className="text-danger">
                    {errors.oldPassword.message}
                  </label>
                )}
              </Form.Group>
              {success?.check && (
                <label className="text-danger">{success?.message}</label>
              )}
              <div className="text-center">
                <Button type="submit" className="theme-btn ms-auto px-5">
                  Change
                </Button>
              </div>

              <ul
                style={{ fontSize: ".8571428571428571rem" }}
                className="list-outside p-0 m-0 mt-4 pe-0 px-4"
              >
                <li>
                  Password must have 8 to 15 alphanumeric without white space
                </li>
                <li>Password cannot be the same as username / nickname</li>
                <li>
                  Must contain at least 1 capital letter, 1 small letter and 1
                  number
                </li>
                
              </ul>
            </Form>
          </div>
        </LayoutComponent>
      
    </div>
  );
};

export default ChangePasswordProfile;
