import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { TiArrowSortedUp } from "react-icons/ti";

const SportAccordianInnerSlip = ({
  res,
  obj,
  premiumDetail,
  handelPaly,
}) => {
  return (
    <div
      title={`${res?.odds}`}
      id={"parly_" + res?.selectionName}
      onClick={() => handelPaly(premiumDetail, res)}
      className={
        !isEmpty(obj) &&
        obj?.sportsBookSelectionObject?.selectionName == res?.selectionName &&
        obj?.sportsBookSelectionObject?.odds == res?.odds
          ? `active ${
              Number(
                document
                  .getElementById("parly_" + res?.selectionName)
                  ?.title?.trim()
              ) !== res?.odds
                ? "spark-parly"
                : ""
            }`
          : `${
              Number(
                document.getElementById("sportBook" + res?.id)?.title?.trim()
              ) !== res?.odds
                ? "spark-parly"
                : ""
            }`
      }
    >
      <span>{res?.selectionName}</span>
      <strong>{res?.odds}</strong>
    </div>
  );
};

export default SportAccordianInnerSlip;
