import React, { useContext, useEffect, useState } from "react";
import HomeBanner from "../components/HomeBanner";
import GamesSlot from "../components/GamesSlot";
import LayoutComponent from "../components/shared/Layout";
import { Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import CurrentNews from "../components/CurrentNews";
const Home = () => {
  const { messagelist, setAnnouncement } = useContext(AuthContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LayoutComponent visibilityType={true}>
        <HomeBanner />
        <CurrentNews message={messagelist} setAnnouncement={setAnnouncement} />
        <GamesSlot />
        <Outlet />
      </LayoutComponent>
    </div>
  );
};

export default Home;
