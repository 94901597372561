import React, { useEffect, useRef, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import obj from "../Utils/constants";
import { TiArrowSortedUp } from "react-icons/ti";
import { MdDeleteForever } from "react-icons/md";

const BetSlipParlyInner = ({ oldObject, res, removeSlip, index }) => {
  const [green, setGreen] = useState(false);
  const [red, setRed] = useState(false);
  const redRef = useRef(false);
  const greenRef = useRef(false);
  useEffect(() => {
    if (res?.sportsBookSelectionObject?.odds > oldObject?.odds) {
      setGreen(true);
      setRed(false);
    } else if (res?.sportsBookSelectionObject?.odds < oldObject?.odds) {
      setGreen(false);
      setRed(true);
    } else if (res?.sportsBookSelectionObject?.odds == oldObject?.odds) {
      if (red && !redRef.current) {
        redRef.current = true; // Mark as executed
        let timeout = setTimeout(() => {
          setRed(false);
          redRef.current = false;
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
      if (green && !greenRef.current) {
        greenRef.current = true;
        let timeout = setTimeout(() => {
          setGreen(false);
          greenRef.current = false;
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [oldObject, res, green, red, redRef, greenRef]);

  return (
    <div class="w-full mt-2 mb-2 px-2">
      <div
        style={{ justifyContent: "space-between" }}
        className="px-1 w-full flex items-center bg-black-300 rounded-t-lg"
      >
        <div
          className="py-2 px-1"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: res?.closed ? "0.6" : "1",
          }}
        >
          {res?.closed && (
            <span
              style={{
                marginRight: "8px",
                fontSize: ".8rem",
              }}
              class="my-4px bg-black-600 leading-5 py-1 mr-2 text-black-300 px-2 rounded font-bold text-13"
            >
              Closed
            </span>
          )}

          <span
            className="d-flex align-items-center"
            style={{
              fontWeight: "700",
              fontSize: ".9285714285714286rem",
            }}
          >
            <span
              style={{
                marginRight: "3px",
                fontWeight: "500",
              }}
            >
              {obj.betCheckObj[res?.eventType]}
            </span>{" "}
            <BiSolidRightArrow
              style={{
                marginRight: "2px",
              }}
              color="rgb(90,94,98)"
            />{" "}
            {res?.eventName || res?.marketName}
          </span>
        </div>
        <MdDeleteForever
          onClick={() => removeSlip(res?.newEventId)}
          size={25}
          // style={{ width: "10%"}}
          color="rgb(139,160,174)"
        />
      </div>
      {!res?.closed && (
        <div
          style={{
            background: "rgb(238,246,251)",
            paddingBottom: "0 !important",
          }}
          class="p-2 w-full bg-black-100"
        >
          <div class="d-flex justify-between">
            <div className="parly-event-name">
              {res?.sportsBookSelectionObject?.selectionName}
            </div>
            <div
              title={res?.sportsBookSelectionObject?.odds}
              id={"parly_" + index}
              style={
                green
                  ? {
                      color: "green",
                    }
                  : red
                  ? { color: "red" }
                  : {}
              }
              class={`parly-odds text-center font-bold bg-black-300 position-relative rounded  ${
                Number(
                  document.getElementById("parly_" + index)?.title?.trim()
                ) !== res?.sportsBookSelectionObject?.odds
                  ? "spark-parly"
                  : ""
              }`}
            >
              {res?.sportsBookSelectionObject?.odds}
              {green && (
                <TiArrowSortedUp
                  style={{
                    position: "absolute",
                    top: "-5%",
                    right: "-3%",
                    transform: "rotate(45deg)",
                  }}
                  size={15}
                  color="green"
                />
              )}
              {red && (
                <TiArrowSortedUp
                  style={{
                    position: "absolute",
                    bottom: "-5%",
                    right: "-3%",
                    transform: "rotate(135deg)",
                  }}
                  size={15}
                  color="red"
                />
              )}
            </div>
          </div>
          <span style={{ fontSize: ".7142857142857143rem" }} class="text-black">
            {res?.marketName}
          </span>
        </div>
      )}
    </div>
  );
};

export default BetSlipParlyInner;
