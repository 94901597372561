import React, { useContext, useEffect, useState } from "react";
import SportGames from "./HomeGames/SportGames";
import LiveGames from "./HomeGames/LiveGames";
import TableGames from "./HomeGames/TableGames";
import SlotGames from "./HomeGames/SlotGames";
import FishingGames from "./HomeGames/FishingGames";
import Egames from "./HomeGames/Egames";
import AuthContext from "../context/AuthContext";
import "swiper/css";
import "swiper/css/scrollbar";
import Slider from "react-slick";
import { isEmpty } from "lodash";
const GamesSlot = () => {
  const { active, setActive } = useContext(AuthContext);
  const [op, setOp] = useState("");
  const [activenew, setActiveNew] = useState(true);
  const [activenew2, setActiveNew2] = useState(true);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    cssEase: "linear",
    afterChange: function (index) {
      setOp(index + 1);
      setActive(active);
    },
  };


  useEffect(() => {
    if (active) {
      let timer = setTimeout(() => {
        setActiveNew(false);
        setSelect("");
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      let timer = setTimeout(() => {
        setActiveNew2(false);
      }, 50);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [active]);

  const [select, setSelect] = useState("");
  const [select2, setSelect2] = useState("");
  const [witdthHeight, setWitdthHeight] = useState({});

  useEffect(() => {
    let obj = document?.getElementById("sport");
    if (!isEmpty(obj)) {
      setWitdthHeight({
        width: obj.offsetWidth,
        height: obj.offsetHeight,
      });
    }
  }, [document?.getElementById("sport")]);

  return (
    <>
      <div className="games-slot new-game-slot position-relative">
        <Slider {...settings}>
          <div
            id={"sport"}
            className={
              active === "sports"
                ? `slot1 activenew ${
                    isEmpty(select) && !activenew ? "active" : ""
                  } ${!activenew2 ? "slotactive" : ""}`
                : `slot1 `
            }
            onClick={(e) => {
              if (active !== "sports") {
                setActiveNew(true);
                setActiveNew2(true);
              }
              const element = document.getElementById("sport");
              const rect = element.getBoundingClientRect();
              setSelect((prev) => {
                return rect.x;
              });
              setSelect2(rect.x);
              setActive("sports");
            }}
          >
            <div>
              <span>Sports</span>
              <i class="icon icon-game-hall-sports"></i>
              {/* {active === "sports" ? (
                <img src="../assets/images/icon-yellow1.png" alt="" />
              ) : (
                <img src="../assets/images/icon1.png" alt="" />
              )} */}
            </div>
          </div>
          <div
            id={"live"}
            className={
              active === "live"
                ? `slot1 activenew ${
                    isEmpty(select) && !activenew ? "active" : ""
                  } ${!activenew2 ? "slotactive" : ""}`
                : `slot1 `
            }
            onClick={(e) => {
              if (active !== "live") {
                setActiveNew(true);
                setActiveNew2(true);
              }
              const element = document.getElementById("live");
              const rect = element.getBoundingClientRect();
              setSelect(rect.x);
              setSelect2(rect.x);
              setActive("live");
            }}
          >
            <div>
              <span>Live</span>
              <i class="icon icon-game-hall-live"></i>
              {/* {active === "live" ? (
                <img src="../assets/images/icon-yellow2.png" alt="" />
              ) : (
                <img src="../assets/images/icon2.png" alt="" />
              )} */}
            </div>
          </div>

          <div
            id={"table"}
            className={
              active === "table"
                ? `slot1 activenew ${
                    isEmpty(select) && !activenew ? "active" : ""
                  } ${!activenew2 ? "slotactive" : ""}`
                : `slot1 `
            }
            onClick={(e) => {
              if (active !== "table") {
                setActiveNew(true);
                setActiveNew2(true);
              }
              const element = document.getElementById("table");
              const rect = element.getBoundingClientRect();
              setSelect(rect.x);
              setSelect2(rect.x);
              setActive("table");
            }}
          >
            <div>
              <span>Table</span>
              <i class="icon icon-game-hall-table"></i>
              {/* {active === "table" ? (
                <img src="../assets/images/icon-yellow3.png" alt="" />
              ) : (
                <img src="../assets/images/icon3.png" alt="" />
              )} */}
            </div>
          </div>

          <div
            id={"slot"}
            className={
              active === "slot"
                ? `slot1 activenew ${
                    isEmpty(select) && !activenew ? "active" : ""
                  } ${!activenew2 ? "slotactive" : ""}`
                : `slot1 `
            }
            onClick={(e) => {
              if (active !== "slot") {
                setActiveNew(true);
                setActiveNew2(true);
              }
              const element = document.getElementById("slot");
              const rect = element.getBoundingClientRect();
              setSelect(rect.x);
              setSelect2(rect.x);
              setActive("slot");
            }}
          >
            <div>
              <span>Slot</span>
              <i class="icon icon-game-hall-slot"></i>
              {/* {active === "slot" ? (
                <img src="../assets/images/icon-yellow4.png" alt="" />
              ) : (
                <img src="../assets/images/icon4.png" alt="" />
              )} */}
            </div>
          </div>

          <div
            id={"fish"}
            className={
              active === "fish"
                ? `slot1 activenew ${
                    isEmpty(select) && !activenew ? "active" : ""
                  } ${!activenew2 ? "slotactive" : ""}`
                : `slot1 `
            }
            onClick={(e) => {
              if (active !== "fish") {
                setActiveNew(true);
                setActiveNew2(true);
              }
              const element = document.getElementById("fish");
              const rect = element.getBoundingClientRect();
              setSelect(rect.x);
              setSelect2(rect.x);
              setActive("fish");
            }}
          >
            <div>
              <span>Fishing</span>
              <i class="icon icon-game-hall-fishing"></i>
              {/* {active === "fish" ? (
                <img src="../assets/images/icon-yellow5.png" alt="" />
              ) : (
                <img src="../assets/images/icon5.png" alt="" />
              )} */}
            </div>
          </div>

          <div
            id={"e-game"}
            className={
              active === "e-game"
                ? `slot1 activenew ${
                    isEmpty(select) && !activenew ? "active" : ""
                  } ${!activenew2 ? "slotactive" : ""}`
                : `slot1 `
            }
            onClick={(e) => {
              {
                if (active !== "e-game") {
                  setActiveNew(true);
                  setActiveNew2(true);
                }
                const element = document.getElementById("e-game");
                const rect = element.getBoundingClientRect();
                setSelect(rect.x);
                setSelect2(rect.x);
                setActive("e-game");
              }
            }}
          >
            <div>
              <span>Egame</span>
              <i class="icon icon-game-hall-egame"></i>
              {/* {active === "e-game" ? (
                <img src="../assets/images/egame-hover.png" alt="" />
              ) : (
                <img src="../assets/images/egame.png" alt="" />
              )} */}
            </div>
          </div>
        </Slider>
        <div
          style={{
            transform: `translateX(${
              // isBrowser ? queryBrowser[active] : query[active]
              select2 - 10
            }px)`,
            opacity: select > 0 ? 1 : 0,
            width:
              witdthHeight?.width > 0 ? `${witdthHeight?.width}px` : "84px",
            height:
              witdthHeight?.height > 0 ? `${witdthHeight?.height}px` : "79px",
          }}
          className="tab-indicator-new"
        ></div>
      </div>

      {active === "sports" && <SportGames />}
      {active === "live" && <LiveGames />}
      {active === "table" && <TableGames />}
      {active === "slot" && <SlotGames />}
      {active === "fish" && <FishingGames />}
      {active === "e-game" && <Egames />}
    </>
  );
};

export default GamesSlot;
