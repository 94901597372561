import React, { useEffect, useState } from "react";
import GameCard from "../GameCard";
import GameSidebar from "../GameSidebar";
import {
  gamesLiveArray,
} from "../../constraints/constants";
import { Button } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Egames = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [homePlayType, setHomePlayType] = useState("latest");
  useEffect(() => {
    setHomePlayType("latest");
    setData(
      gamesLiveArray
        ?.filter((res) => {
          return res?.type == "EGAME";
        })
        ?.slice(0, 12)
    );
  }, []);
  const handelChange = (value) => {
    setHomePlayType(value);
    if (value == "latest") {
      setData(
        gamesLiveArray
          ?.filter((res) => {
            return res?.type == "EGAME";
          })
          ?.slice(0, 12)
      );
    } else {
      setData((prev) => {
        return prev.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      });
    }
  };
  return (
    <div className="games-inner">
      <GameSidebar sideDataType="tableData"  homePlayType={homePlayType}
        setHomePlayType={handelChange}/>
      <div className="games-wrapper">
        <div className="games-card-inner">
          <div className="row">
            {data?.map((res, index) => {
                return <GameCard key={index} res={res} />;
              })}
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Button
            onClick={() => {
              navigate(`/casino?category=egame`);
            }}
            style={{ background: "black", border: "none" }}
          >
            Show More <IoIosArrowForward />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Egames;
