import React from "react";
import LayoutComponent from "../components/shared/Layout";
import Slider from "react-slick";
import { Accordion, Col, Row } from "react-bootstrap";
import { FaArrowsAltV } from "react-icons/fa";

const ParlayFullMarket = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <LayoutComponent visibilityType={true}>
      <div className="live-score">
        <span className="active">Live</span>
        <span className="">Scoreboard</span>
      </div>
      <div className="team-score d-flex justify-content-between text-center py-sm-3 py-2">
        <span className="d-block w-100">Sri Lanka</span>
        <span className="minus">{"-"}</span>
        <span className="d-block w-100">Oman</span>
      </div>
      <div className="live-score-iframe">
        <img src="../assets/images/live-score.png" alt="" className="w-100" />
      </div>

      <div className="sports-tab-panel py-2 bg-yellow">
        <Slider {...settings}>
          <div>
            <h3 className="active">All</h3>
          </div>
          <div>
            <h3>Popular</h3>
          </div>
          <div>
            <h3>Over</h3>
          </div>
          <div>
            <h3>Innings</h3>
          </div>
          <div>
            <h3>Players</h3>
          </div>
        </Slider>
      </div>

      <div className="sport-listing parlay-acc pt-0">
        <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="ps-3">
              1st innings over 1 - Sri Lanka total
            </Accordion.Header>
            <Accordion.Body className="p-2 pt-0">
              <div className="sports-listing-score">
                <Row className="gx-1">
                  {Array(4)
                    .fill()
                    .map((item, index) => {
                      return (
                        <Col xs={6}>
                          <div className="text-center team-wise-score">
                            <span className="d-block">over 4.5</span>
                            <strong className="text-success">2.16</strong>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="ps-3">
              1st innings over 2 - Sri Lanka total
            </Accordion.Header>
            <Accordion.Body className="p-2 pt-0">
              <div className="sports-listing-score">
                <Row className="gx-1">
                  {Array(4)
                    .fill()
                    .map((item, index) => {
                      return (
                        <Col xs={6}>
                          <div className="text-center team-wise-score">
                            <span className="d-block">under 4.5</span>
                            <strong className="text-danger">2.16</strong>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header className="ps-3">
              1st innings over 3 - Sri Lanka total
            </Accordion.Header>
            <Accordion.Body className="p-2 pt-0">
              <div className="sports-listing-score">
                <Row className="gx-1">
                  {Array(4)
                    .fill()
                    .map((item, index) => {
                      return (
                        <Col xs={6}>
                          <div className="text-center team-wise-score">
                            <span className="d-block">England</span>
                            <strong>2.16</strong>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>


          <div className="market-depth-sec d-flex justify-content-end py-2">
          <div className="d-flex align-items-center min-max ">
            <FaArrowsAltV />
            <span>min 1</span>
          </div>
        </div>
        </Accordion>


      </div>
    </LayoutComponent>
  );
};

export default ParlayFullMarket;
