import React, { useEffect } from "react";
import { GiSoccerBall, GiTennisBall } from "react-icons/gi";
import { BiSolidCricketBall } from "react-icons/bi";

import { AiFillTrophy } from "react-icons/ai";

const GameMenu = ({ game, setGame, type, loader }) => {
  useEffect(() => {
    let timer = setTimeout(() => {
      document.getElementById("loading").style.display = "none";
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [game]);
  return (
    <div className="game-menu">
      <ul className="p-0 mb-0">
        {!loader && (
          <li
            onClick={() => {
              setGame("all");
              if (loader) {
                document.getElementById("loading").style.display = "flex";
              }
            }}
            className={game === "all" ? "active" : ""}
          >
            <div>
              <i class="icon-all"></i>
            </div>
            All
          </li>
        )}
        <li
          onClick={() => {
            setGame("cricket");
            if (loader) {
              document.getElementById("loading").style.display = "flex";
            }
          }}
          className={game === "cricket" ? "active" : ""}
        >
          <div>
            <i class="icon-cricket"></i>
          </div>
          Cricket
        </li>
        <li
          onClick={() => {
            setGame("soccer");
            if (loader) {
              document.getElementById("loading").style.display = "flex";
            }
          }}
          className={game === "soccer" ? "active" : ""}
        >
          <div>
            <i class="icon-soccer"></i>
          </div>
          Soccer
        </li>
        <li
          onClick={() => {
            setGame("tennis");
            if (loader) {
              document.getElementById("loading").style.display = "flex";
            }
          }}
          className={game === "tennis" ? "active" : ""}
        >
          <div>
            <i class="icon-tennis"></i>
          </div>
          Tennis
        </li>
        {/* {type == 'home' &&
        <li
          onClick={() => setGame("virtual")}
          className={game === "virtual" ? "active" : ""}
        >
          <div>
          <GiSoccerBall />
          </div>
          Virtual
        </li>} */}
      </ul>
    </div>
  );
};

export default GameMenu;
