import React, { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import "../src/assets/css/style.css";
import "../src/assets/css/icomoon.css";
import "../src/assets/css/media.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./pages/Home";
import Login from "./Auth/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import BalanceOverview from "./pages/BalanceOverview";
import Logs from "./pages/Logs";
import { Fade } from "react-awesome-reveal";
import Profile from "./pages/Profile";
import ChangePassword from "./Auth/ChangePassword";
import ChangePasswordProfile from "./Auth/ChangePasswordProfile";
import P2ptransfer from "./pages/P2ptransfer";
import Settings from "./pages/Settings";
import BetsHistory from "./pages/BetsHistory";
import Ptransferlog from "./pages/Ptransferlog";
import ProfitLoss from "./pages/ProfitLoss";
import Casino from "./pages/casino/Casino";
import Sports from "./pages/Sports";
import Leagues from "./pages/Leagues";
import ParlayFullMarket from "./pages/ParlayFullMarket";
import UplineWhatsapp from "./pages/UplineWhatsapp";
import { AuthProvider } from "./context/AuthContext";
import { BetSlipProvider } from "./context/BetSlipContext";
import CurrentBets from "./pages/CurrentBets/CurrentBets";
import FullMarket from "./pages/MatchDetail/FullMarket";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import AccountStatement from "./pages/AccountStatement";
import Bg from "./assets/images/velki-logo-desktop.webp";
import Deposit from "./pages/Deposit";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
import UpdateProfile from "./Auth/UpdateProfile";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <AuthProvider>
          <BetSlipProvider>
            <Outlet />
          </BetSlipProvider>
        </AuthProvider>
      }
    >
      <Route exact path="/bkash/:token" element={<Home />}></Route>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/balance-overview" element={<BalanceOverview />} />
      <Route path="/current-bets" element={<CurrentBets />} />
      <Route path="/active-log" element={<Logs />} />
      <Route path="/my-profile" element={<Profile />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/change-password-profile" element={<ChangePasswordProfile />} />

      <Route path="/p2p-transfer" element={<P2ptransfer />} />
      <Route path="/setting" element={<Settings />} />
      <Route path="/deposit" element={<Deposit />} />
      <Route path="/bets-history" element={<BetsHistory />} />
      <Route path="/p2p-transfer-log" element={<Ptransferlog />} />
      <Route path="/account-statement" element={<AccountStatement />} />
      <Route path="/profit-and-loss" element={<ProfitLoss />} />{" "}
      <Route path="/casino" element={<Casino />} />
      <Route path="/sports" element={<Sports />} />
      <Route path="/leagues" element={<Leagues />} />
      <Route path="/full-market" element={<FullMarket />} />
      <Route path="/full-market/:marketId/:eventId" element={<FullMarket />} />
      <Route path="/parlay-full-market" element={<ParlayFullMarket />} />
      <Route path="/upline-whatsapp-number" element={<UplineWhatsapp />} />
      <Route path="/update-profile" element={<UpdateProfile />} />
      
    </Route>
  )
);

const App = () => {
  useEffect(() => {
    if (isBrowser) {
      document.body.classList.add("background-body");
    } else {
      document.body.classList.remove("background-body");
    }
  }, [isMobile, isBrowser]);

  const customAnimation = keyframes`
  from {
    opacity: 0;
    background:black
  }

  to {
    opacity: 1; 
    background:black
  }
`;
 
  return (
    <Reveal keyframes={customAnimation}>
      <Fade cascade duration={1500}>
        <div className="position-relative">
          {isBrowser && <img src={Bg} className="new-bg" />}
          <RouterProvider router={router} />
        </div>
      </Fade>
    </Reveal>
  );
};

export default App;
