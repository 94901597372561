import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Table } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
const MarketDepth = ({ setShowMarketDepthPopup, data, detail }) => {
  const [select, setSelect] = useState(data?.runner[0]?.RunnerName);
  const [selectId, setSelectId] = useState(data?.runner[0]?.SelectionId);

  let first = data?.runner[0]?.RunnerName;
  let second = data?.runner[1]?.RunnerName;

  let firstId = data?.runner[0]?.SelectionId;
  let secondId = data?.runner[1]?.SelectionId;

  const [lay, setLay] = useState([]);
  const [back, setBack] = useState([]);

  useEffect(() => {
    setLay(
      data?.layOdds?.filter((res) => {
        return res?.ri == data?.runner[0]?.SelectionId;
      })
    );
    setBack(
      data?.backOdds?.filter((res) => {
        return res?.ri == data?.runner[0]?.SelectionId;
      })
    );
  }, []);

  return (
    <div className="market-depth-modal slide-up">
      <div className="market-title text-white">
      <h4 className="text-white">Market Depth</h4>
        <Button
          className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
          onClick={() => setShowMarketDepthPopup(false)}
        >
          <RxCross2 />
        </Button>
      </div>

      <div className="market-depth-body">
        <div className="match-point">
          {" "}
          <span className="master-pass">{detail?.eventName}</span>
          <span className="master-pass">Match Odds</span>
        </div>

        <div className="select-container">
          <DropdownButton key={selectId} title={select}>
            <Dropdown.Item
              onClick={() => {
                setSelect(select !== first ? first : second);
                setSelectId(selectId !== firstId ? firstId : secondId);
                let id = selectId !== firstId ? firstId : secondId;
                setLay(
                  data?.layOdds?.filter((res) => {
                    return res?.ri == id;
                  })
                );
                setBack(
                  data?.backOdds?.filter((res) => {
                    return res?.ri == id;
                  })
                );
              }}
              eventKey={select !== first ? first : second}
            >
              {select !== first ? first : second}
            </Dropdown.Item>
          </DropdownButton>
        </div>

        <div className="mark-depth-total">
              <Table className=" border">
                        <tbody>
                          <tr>
                            <td className="text-center">
                              Total matched
                              <strong class="d-block">{data?.totalMatched || 0.0}</strong>
                            </td>
                            <td className="text-center">
                              Selection Volume
                              <strong class="d-block">26547.56</strong>
                            </td>
                            <td className="text-center">
                              Last price
                              <strong class="d-block">1.13</strong>
                            </td>
                          </tr>
                        </tbody>
                      </Table>

              </div>

        <div className="balance-label position-relative  exchange-label">
          Price, Exchange Available and Traded
        </div>

        <div className="d-flex justify-content-between p-2">
          {back?.length > 0 && (
            <span className="bet-name back rounded fw-normal backmd">
            <FaAngleLeft className="text-success" />
            to Back
          </span>
          )}
          {lay?.length > 0 && (
             <span className="bet-name lay rounded fw-normal laymd">
              to Lay
              <FaAngleRight className="text-danger" />
            </span>
          )}
        </div>

        <div className="to-back-to-lay">
          <Table className="">
            <thead>
              <tr>
                {lay?.length > 0 &&
                  lay?.map((res) => {
                    return (
                      <th className="fw-normal text-center bet-name lay rounded-0">
                        <strong className="d-block">{res?.rt}</strong>
                        {res?.bv}
                      </th>
                    );
                  })}
                {back?.length > 0 &&
                  back?.map((res) => {
                    return (
                      <th className="fw-normal text-center bet-name back rounded-0">
                        <strong className="d-block">{res?.rt}</strong>
                        {res?.bv}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1553.25</td>
                <td>1553.25</td>
                <td>1553.25</td>
                <td>1553.25</td>
                <td>1553.25</td>
                <td>1553.25</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default MarketDepth;
