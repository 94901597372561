import React, { useContext } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { sidebarData } from "../constraints/constants";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
import OutsideClickHandler from "react-outside-click-handler";
import { IoIosArrowForward } from "react-icons/io";
const MenuSidebar = () => {
  let { logoutUser, setShowSidebar, currentBetCount } = useContext(AuthContext);
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowSidebar(false);
      }}
    >
      <div>
        <div className="sidebar-wrapper">
          <div className="top-sidebar mb-2 px-2 mt-1 d-flex justify-content-between">
            <strong>Menu</strong>
            <i
              onClick={() => setShowSidebar(false)}
              class="icon icon-close"
            ></i>
          </div>
          <ul className="p-0 m-0 mb-3">
            {sidebarData.length > 0 &&
              sidebarData[0].firstLevel.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item?.link} className="text-decoration-none">
                      {item?.icon} <span>{item?.menu}</span>{" "}
                      {item?.menu === "Current Bets" && (
                        <strong className="ms-2 bg-yellow d-block p-2 py-0 fs-6 rounded text-white">
                          {currentBetCount || 0}
                        </strong>
                      )}
                    </Link>
                    {/* <IoIosArrowForward /> */}
                  </li>
                );
              })}
          </ul>
          <ul className="p-0 m-0 mb-3">
            {sidebarData.length > 0 &&
              sidebarData[0].secondLevel.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item?.link} className="text-decoration-none">
                      {item?.icon} <span>{item?.menu}</span>{" "}
                      {item?.menu === "Current Bets" && (
                        <strong
                          style={{
                            fontSize: ".8571428571428571rem",
                            fontWeight: "700",
                          }}
                          className="ms-2 bg-yellow d-block p-2 py-0 rounded"
                        >
                          {currentBetCount || 0}
                        </strong>
                      )}
                    </Link>
                  </li>
                );
              })}
          </ul>
          <ul className="p-0 m-0 mb-3">
            {sidebarData.length > 0 &&
              sidebarData[1].thirdLevel.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item?.link} className="text-decoration-none">
                      {item?.icon} <span>{item?.menu}</span>
                    </Link>
                  </li>
                );
              })}
          </ul>

          <ul className="p-0 m-0 mb-3">
            {sidebarData.length > 0 &&
              sidebarData[2].fourthLevel.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item?.link} className="text-decoration-none">
                      {item?.icon}
                      <span>{item?.menu}</span>
                    </Link>
                  </li>
                );
              })}
          </ul>

          <button onClick={() => logoutUser()} className="logout-button">
            <i class="icon icon-logout"></i>
            Logout
          </button>
          <div className="time-zone text-center pt-4">
            <AiOutlineGlobal className="me-2 fs-4" />{" "}
            <span>Time Zone：GMT+6:00</span>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MenuSidebar;
