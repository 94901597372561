import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Bkash from "../assets/images/bkash.webp";
import nagad from "../assets/images/nagad.png";
import rocket from "../assets/images/rocket.png";
import upay from "../assets/images/upay.png";
import LayoutComponent from "../components/shared/Layout";
import { BsInfoCircleFill } from "react-icons/bs";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LuRefreshCw } from "react-icons/lu";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
const Deposit = () => {
  const [select, setSelect] = useState("Deposit");
  const [bank, setBank] = useState(0);
  const [accordian, setAccordian] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LayoutComponent visibilityType={true}>
      <div className="deposit">
        <div className="deposit-header">
          <span>Funds</span>
          <IoMdClose onClick={() => navigate(-1)} color="white" size={20} />
        </div>
        <div className="depsit-inner-header">
          <span
            onClick={() => setSelect("Deposit")}
            className={select == "Deposit" && "active"}
          >
            Deposit
          </span>{" "}
          <span
            onClick={() => setSelect("Withdraw")}
            className={select == "Withdraw" && "active"}
          >
            Withdraw
          </span>
        </div>
        {select == "Withdraw" && (
          <div className="withdraw-header">
            <div className="">
              <span style={{ fontWeight: "600", fontSize: "16px" }}>
                Withdraw Amount
              </span>
              <span
                style={{
                  fontSize: "1.5714285714285714rem",
                  color: "rgb(111 136 152/1)",
                }}
              >
                PBU <span style={{ fontWeight: "600" }}>0.00</span>
              </span>
            </div>
            <LuRefreshCw size={23} />
          </div>
        )}
        <div className="deposit-payment">
          <span className="payment-header">Payment Methods</span>
          <div className="payment-option">
            <div onClick={() => setBank(1)} className={bank == 1 && "active"}>
              <img src={Bkash} />
              <span>Bkash</span>
            </div>
            <div onClick={() => setBank(2)} className={bank == 2 && "active"}>
              <img src={nagad} />
              <span>Nagad</span>
            </div>
            <div onClick={() => setBank(3)} className={bank == 3 && "active"}>
              <img src={rocket} />
              <span>Rocket</span>
            </div>
            <div onClick={() => setBank(4)} className={bank == 4 && "active"}>
              <img src={upay} />
              <span>Upay</span>
            </div>
          </div>
        </div>
        <div className="deposit-payment">
          <span className="payment-header">Your Wallet Payment</span>
          <div className="payment-input">
            <input />
          </div>
        </div>
        <div className="deposit-payment">
          <span className="payment-header">
            Deposit Amount{" "}
            <span className="payment-min-max">
              <BsInfoCircleFill
                color="rgb(111 136 152/1)"
                size={14}
                style={{ marginRight: "4px" }}
              />
              ৳ 200 - ৳ 15000
            </span>
          </span>

          <div className="payment-input">
            <div className="payment-input-option">
              <span>৳ 200</span> <span>৳ 1,500</span> <span>৳ 2,000</span>{" "}
              <span>৳ 10,000</span>
            </div>
            <input style={{ borderRadius: "4px" }} />
            <div class="d-flex align-items-center justify-content-end payment-min-max-new">
              <div>You will pay</div>
              <div style={{ fontWeight: "600", margin: "0 4px" }}> ৳ 0</div>
              <div>to get</div>
              <div style={{ fontWeight: "600", margin: "0 4px" }}>PBU 0.00</div>
            </div>
          </div>

          <Accordion
            onSelect={(e) => setAccordian(e)}
            activeKey={accordian}
            className="deposit-accordian"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Gentle reminder{" "}
                <span className="deposit-accordian-icon">
                  {!accordian ? (
                    <MdKeyboardDoubleArrowDown />
                  ) : (
                    <MdKeyboardDoubleArrowUp />
                  )}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <div class="list-decimal">
                  <div>
                    Dear all member, to speed up your deposit process, kindly
                    follow these steps:
                  </div>
                  <ol type="1">
                    <li>verify the account number you used for deposit.</li>
                    <li>Input the correct reference number.</li>
                    <li>Process only selected amount.</li>
                    <li>Attach the successful Slip.</li>
                  </ol>
                  <div>Reminder:</div>
                  <div>
                    Do not save the phone number displayed on Velki website on
                    your device to avoid losing money.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="d-flex justify-content-center align-items-center">
            <button className="w-100 deposit-submit">Submit</button>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default Deposit;
