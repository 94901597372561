import React, { useContext, useEffect, useState } from "react";
import GameSidebar from "../GameSidebar";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const SportGames = () => {
  const { keyTime, setKeyTime, setGameTab, setParly, loaded, setLoaded } =
    useContext(AuthContext);
  const [count, setCount] = useState({
    cricketInplayCount: 0,
    soccerInplayCount: 0,
    tennisInplayCount: 0,
    virtualInplayCount: 0,
  });
  const [loader, setLoader] = useState(loaded ? true : false);
  const navigate = useNavigate();
  const inPlayCount = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.inPlayCountMobile,
      {
        type: keyTime,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setCount(response_users.results);
          setTimeout(() => {
            setLoader(false);
            setLoaded(false);
          }, 500);
        }
      } else {
        setTimeout(() => {
          setLoader(false);
          setLoaded(false);
        }, 500);
      }
    } else {
      setTimeout(() => {
        setLoader(false);
        setLoaded(false);
      }, 500);
    }
  };
  useEffect(() => {
    inPlayCount();
  }, [keyTime]);
  return (
    <div className="games-inner">
      <GameSidebar
        sideDataType="sportData"
        keyTime={keyTime}
        setKeyTime={setKeyTime}
      />
      {loader ? (
        <div className="w-100">
          <div className="mb-2">
            <SkeletonTheme baseColor="#d4e0e5">
              <div className="skeletion-new p-3">
                <div className="d-flex mb-2">
                  <Skeleton height={17} width={120} />{" "}
                </div>
                <Skeleton height={17} width={"100%"} />{" "}
                <Skeleton height={17} width={"95%"} />
              </div>
            </SkeletonTheme>
          </div>

          <div className="mb-2">
            <SkeletonTheme baseColor="#d4e0e5">
              <div className="skeletion-new p-3">
                <div className="d-flex mb-2">
                  <Skeleton height={17} width={120} />{" "}
                </div>
                <Skeleton height={17} width={"100%"} />{" "}
                <Skeleton height={17} width={"95%"} />
              </div>
            </SkeletonTheme>
          </div>
          <div className="mb-2">
            <SkeletonTheme baseColor="#d4e0e5">
              <div className="skeletion-new p-3">
                <div className="d-flex mb-2">
                  <Skeleton height={17} width={120} />{" "}
                </div>
                <Skeleton height={17} width={"100%"} />{" "}
                <Skeleton height={17} width={"95%"} />
              </div>
            </SkeletonTheme>
          </div>
          <div className="mb-2">
            <SkeletonTheme baseColor="#d4e0e5">
              <div className="skeletion-new p-3">
                <div className="d-flex mb-2">
                  <Skeleton height={17} width={120} />{" "}
                </div>
                <Skeleton height={17} width={"100%"} />{" "}
                <Skeleton height={17} width={"95%"} />
              </div>
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <div className="games-wrapper">
          <div className="">
            <div
              onClick={() => {
                setGameTab("all");
                navigate("/sports");
                setParly(false);
              }}
              className={"card card1"}
            >
              <span className="d-block">All</span>

              <strong>
                {count?.cricketInplayCount +
                  count?.soccerInplayCount +
                  count?.tennisInplayCount +
                  (count?.virtualInplayCount || 0)}
              </strong>
            </div>
            <div
              onClick={() => {
                setGameTab("cricket");
                navigate("/sports");
                setParly(false);
              }}
              className={"card card2"}
            >
              <span className="d-block">Cricket</span>

              <strong>{count?.cricketInplayCount || 0}</strong>
            </div>

            <div
              onClick={() => {
                setGameTab("soccer");
                navigate("/sports");
                setParly(false);
              }}
              className={"card card3"}
            >
              <span className="d-block">Soccer</span>

              <strong>{count?.soccerInplayCount || 0}</strong>
            </div>

            <div
              onClick={() => {
                setGameTab("tennis");
                navigate("/sports");
                setParly(false);
              }}
              className={"card card4"}
            >
              <span className="d-block">Tennis</span>

              <strong>{count?.tennisInplayCount || 0}</strong>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SportGames;
