import React, { useContext, useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { IoChevronBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import LayoutComponent from "../components/shared/Layout";
import { IoIosArrowBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { preventMaxInput, validationRules } from "../Utils/constants";
import { isEmpty, pick } from "lodash";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
const UpdateProfile = () => {

  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [success, setSuccess] = useState({
    check: true,
    message: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    trigger,
    setValue,
  } = useForm({});

  const [isLoader, setLoader] = useState(false);



  const onSubmit = async (request) => {


      let  obj = pick(request, [
          "email",
          "firstName",
          "lastName",
        ]);
    
    

    
        /**
         * ! request.userType = "super_admin";
         * * This should be managed by Backend.
         */
        try {
          const { status, data: response_users } = await apiPost(
            apiPath.userProfileUpdate,
            { ...obj}
          );
          if (status === 200) {
            if (response_users.success) {
              setLoader(false);
              setSuccess({ check: true, message: response_users?.message });
              
           
            } else {
              setLoader(false);
              setSuccess({ check: true, message: response_users?.message });
            }
          } else {
            setLoader(false);
            setSuccess({ check: true, message: response_users?.message });
          }
        } catch (err) {
          setLoader(false);
         
          setSuccess({ check: true, message: err.response.data.message });
        }
      
    
  };

  return (
    <div>
      <LayoutComponent>
        <div className="d-flex align-items-center justify-content-start">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "white",
              borderRadius: "50%",
              marginLeft: "8px",
              marginTop: "8px",
            }}
            onClick={() => navigate("/")}
          >
            <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
          </div>
        </div>
        <div className="login-log-sec w-100 ">
          <div className="login-logo">
            <img src="../assets/images/velki-logo.png" alt="" />
          </div>
          <div className="px-3">
            <img src="../assets/images/velki-login-signup-banner.png" alt="" />
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} className="bg-white p-4 update-p">
            <label className="mb-3 d-block text-center fs-2">
             Edit Profile
            </label>
            <Form.Group className="mb-3">
              <FloatingLabel controlId="floatingPassword" label="First Name">
              <Form.Control
                      type="text"
                      placeholder="Enter FirstName"
                      className={errors.firstName ? " is-invalid " : ""}
                      {...register("firstName")}
                    />
                    {errors.firstName && errors.firstName.message && (
                      <label className="invalid-feedback text-left">
                        {errors.firstName.message}
                      </label>
                    )}
                
                
              </FloatingLabel>
             
            </Form.Group>
            <Form.Group className="mb-3">
              <FloatingLabel controlId="floatingPassword" label="Last Name">
              <Form.Control
                      type="text"
                      placeholder="Enter LastName"
                      className={errors.lastName ? " is-invalid " : ""}
                      {...register("lastName")}
                    />
                    {errors.lastName && errors.lastName.message && (
                      <label className="invalid-feedback text-left">
                        {errors.lastName.message}
                      </label>
                    )}
              </FloatingLabel>
              {errors.newPassword && errors?.newPassword?.message && (
                <label className="text-danger">
                  {errors.newPassword.message}
                </label>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <FloatingLabel
                controlId="floatingPassword"
                label="Email"
              >
                <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      className={errors.email ? " is-invalid " : ""}
                      {...register("email", {
                        required: "Please enter email",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address.",
                        },
                      })}
                    /> 
                    {errors.email && errors.email.message && (
                      <label className="invalid-feedback text-left">
                        {errors.email.message}
                      </label>
                    )}
              </FloatingLabel>
             
            </Form.Group>
            {success?.check && (
              <label className="text-danger">{success?.message}</label>
            )}
            <div className="text-center mt-3">
              <Button type="submit" className="theme-btn ms-auto px-5"  disabled={isLoader ? true : false}>
                Update
              </Button>
            </div>
           
           
          </Form>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default UpdateProfile;
