import React from "react";
import { AiOutlineAudio } from "react-icons/ai";
const CurrentNews = ({ message, setAnnouncement }) => {
  return (
    <>
      <div
        onClick={() => {
          // if (message?.length > 0) {
          setAnnouncement(true);
          // }
        }}
        className="marquee-notification d-flex"
      >
        <div className="audio-trakcer">
        <i class="icon-mic"></i>
        </div>

        {message?.length > 0 ? (
          <marquee direction="left" scrollamount={5}>
            {message?.map((res) => {
              return (
                <span style={{ fontSize: ".7857142857rem", fontWeight: "700" }}>
                  {res?.message}
                </span>
              );
            })}
          </marquee>
        ) : (
          ""
          // <marquee direction="left" scrollamount={5}>
          //   <span style={{ fontSize: ".7857142857rem", fontWeight: "700" }}>
          //     If any user ID or agent ID is hacked in velkiiex123, the company
          //     will immediately cancel it. And the IDs will be auto-suspended and
          //     locked{" "}
          //   </span>
          // </marquee>
        )}
      </div>
    </>
  );
};

export default CurrentNews;
