import React from "react";

const BattingIframe = ({ detail }) => {
  return (
    <iframe
      className=" w-100"
      style={{
        // overflowX: "hidden",
        // overflow: "hidden",
        // height: "510px",
        // height: `${
        //   document.getElementsByClassName("widgets")[0]?.clientHeight
        // }px`,
        height:"180px",
        backgroundColor: "#000000",
      }}
      id="scoreId"
      // onLoad={load}
      src={detail?.scoreURL}
    ></iframe>
  );
};

export default BattingIframe;
