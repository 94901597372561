import { isNaN } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TiArrowSortedUp } from "react-icons/ti";
import { Col } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
const SportBookInner = ({
  oldObject,
  res,
  handelChange,
  item,
  index,
  parly,
  activeClass,
  newPosition,
  conditionPosition,
  setSelected,
  selectSpark,
  conditionShowPositon,
}) => {
  return (
    <Col className="position-relative" xs={6}>
      <div
        onClick={() => {
          handelChange(res?.odds, item, res, index);
          setSelected(item?.id);
        }}
        title={res?.odds}
        id={"sportBook" + res?.id}
        className={`text-center position-relative team-wise-score ${
          res?.odds > oldObject?.odds || res?.odds < oldObject?.odds
            ? "spark-parly"
            : ""
        } ${selectSpark?.includes(index) ? "spark-parly" : ""} ${
          parly ? "parly-wise-score" : ""
        } ${
          (!parly && Math.abs(res?.position) >= 0) || conditionPosition
            ? "postion-with-height"
            : ""
        } ${activeClass}  `}
      >
        <span style={parly ? { fontWeight: "400" } : {}} className="d-block">
          {res?.selectionName}
        </span>
        <strong>{res?.odds}</strong>
        <div className="d-flex">
          {!parly && conditionShowPositon && (
            <span
              className={`${res?.position > 0 ? "text-green " : "text-red "}`}
              style={{
                background: "#c7e4f8",
                borderRadius: "8px",
                textAlign: "center",
                padding: "1px 5px",
              }}
            >
              {res?.position > 0
                ? Math.abs(res?.position).toFixed(2)
                : `( ${Math.abs(res?.position).toFixed(2)} )`}
            </span>
          )}
          {!parly && !isNaN(Math.abs(newPosition) > 0) && conditionPosition && (
            <span
              className={`${newPosition > 0 ? "text-green " : "text-red "}`}
              style={{
                background: "#c7e4f8",
                borderRadius: "8px",
                textAlign: "center",
                padding: "1px 5px",
                marginLeft: "5px",
              }}
            >
              {/* <MdKeyboardArrowRight /> */}

              {newPosition > 0
                ? Math.abs(newPosition).toFixed(2)
                : `( ${Math.abs(newPosition).toFixed(2)} )`}
            </span>
          )}
        </div>
      </div>
    </Col>
  );
};

export default SportBookInner;
