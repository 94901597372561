import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import SidebarLayout from "../components/shared/SidebarLayout";

const Settings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SidebarLayout heading={"Setting"}>
      <div className="p-3 pt-4 inner-sidebar-content">
        <Form className="p2transfer-form login-panel setting-panel">
          <div className="mb-2">
            <Form.Label
              style={{ fontSize: "1.0714285714285714rem", fontWeight: "700" }}
            >
              Odds
            </Form.Label>

            {/* <div
              className="bg-white p-2 rounded"
              style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}
            >
              <Form.Check
                type="checkbox"
                style={{ fontSize: "1.0714285714285714rem" }}
                id="odds"
                label="Highlight when odds change"
              />
            </div> */}

            <div
              style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}
              className="bg-white p-2 rounded py-3 setting md-switch md d-flex justify-content-between align-items-center"
            >
              <span style={{ fontSize: "1.0714285714285714rem" }}>
                Highlight when odds change
              </span>
              <input
                type="checkbox"
                id="marketing-promotions"
                name="marketing-promotions"
                title="Sign Up for Marketing Promotions"
                class="md-toggle md-toggle-round"
              />
              <label for="marketing-promotions">
                <span></span>
              </label>
            </div>
          </div>

          <div>
            <Form.Label
              style={{ fontSize: "1.0714285714285714rem", fontWeight: "700" }}
            >
              Events Widget
            </Form.Label>

            {/* <div
              style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}
              className="bg-white p-2 rounded py-3 setting"
            >
              <Form.Check
                type="checkbox"
                id="Widget"
                style={{ fontSize: "1.0714285714285714rem" }}
                label="Show in Full-Markets"
              />
            </div> */}

            <div
              style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}
              className="bg-white p-2 rounded py-3 setting md-switch md d-flex justify-content-between align-items-center"
            >
              <span style={{ fontSize: "1.0714285714285714rem" }}>
                Show in Full-Markets
              </span>
              <input
                type="checkbox"
                id="marketing-promotions2"
                name="marketing-promotions2"
                title="Sign Up for Marketing Promotions"
                class="md-toggle md-toggle-round"
              />
              <label for="marketing-promotions2">
                <span></span>
              </label>
            </div>
          </div>
        </Form>
      </div>
    </SidebarLayout>
  );
};

export default Settings;
