import React from "react";
import SportBookInner from "./SportBookInner";
import { Accordion, Row } from "react-bootstrap";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { isEmpty } from "lodash";

const SportBookMatchInner = ({
  updatedData,
  parly,
  setSelectSpark,
  scrollHeight,
  myRef,
  scrollId,
  betSlipObject,
  handelChange,
  setSelected,
  selectSpark,
  showBetDialog,
  selected,
  sportBookCheck,
  parlyBet,
  detail,
  position,
}) => {
  return (
    <div className={`sport-listing ${parly ? "sport-parly-list" : ""}`}>
      {updatedData?.map((item, index) => {
        let condition =
          item?.marketStatus == 9 ||
          item?.marketStatus == 2 ||
          item?.marketStatus == 3 ||
          item?.marketStatus == 4;
        if (item?.sportsBookSelection?.length > 0) {
          return (
            <Accordion
              onSelect={(e) => {
                if (selectSpark?.includes(e)) {
                  setSelectSpark((prev) => {
                    return prev?.filter((res) => {
                      return res !== e;
                    });
                  });
                } else {
                  setSelectSpark((prev) => {
                    return [...prev, e];
                  });
                  setTimeout(() => {
                    setSelectSpark((prev) => {
                      return prev?.filter((res) => {
                        return res !== e;
                      });
                    });
                  }, 1000);
                }
              }}
              defaultActiveKey={[0, 1, 2, 3, 4]}
            >
              <Accordion.Item eventKey={index}>
                <Accordion.Header
                  style={{ scrollMarginTop: scrollHeight }}
                  ref={index == scrollId ? myRef : null}
                >
                  {parly ? (
                    <FaRegArrowAltCircleUp
                      size={25}
                      style={{ margin: "1px 6px" }}
                    />
                  ) : (
                    <div
                      style={{ width: "3.8rem", background: "" }}
                      className={`fave  bg-star-sprit-black  start-0 `}
                    ></div>
                  )}
                  {item?.marketName}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <div className="sports-listing-score">
                    <Row
                      className={`gx-1 ${
                        item?.apiSiteStatus !== "ACTIVE"
                          ? "sports-book-disabled"
                          : condition
                          ? "sports-book-disabled"
                          : ""
                      }`}
                    >
                      {(item?.apiSiteStatus !== "ACTIVE" || condition) && (
                        <strong className="sports-book-disabled-text">
                          {item?.apiSiteStatus !== "ACTIVE"
                            ? "Suspended"
                            : item?.marketStatus == 9
                            ? "Ball Running"
                            : item?.marketStatus == 2
                            ? "In Active"
                            : item?.marketStatus == 3
                            ? "Suspended"
                            : item?.marketStatus == 4
                            ? "Closed"
                            : "Suspended"}
                        </strong>
                      )}

                      {item?.sportsBookSelection?.map((res) => {
                        let newPosition = "";
                        if (
                          showBetDialog &&
                          betSlipObject?.playType == "sportBook" &&
                          item?.id == selected
                        ) {
                          let backProfit =
                            (Number(betSlipObject?.odds) - 1) *
                            Number(betSlipObject?.bidPrice);
                          let backLoss = Number(betSlipObject?.bidPrice);
                          if (res?.id == betSlipObject?.object?.id) {
                            newPosition =
                              Math.abs(res?.position) > 0
                                ? Number(backProfit) + Number(res.position)
                                : Number(backProfit);
                          } else {
                            newPosition =
                              Math.abs(res?.position) > 0
                                ? Number(res?.position) - Number(backLoss)
                                : -Number(backLoss);
                          }
                        }

                        let activeClass = parly
                          ? sportBookCheck(parlyBet, detail, res)
                          : betSlipObject?.teamName == res?.selectionName &&
                            betSlipObject?.odds == res?.odds &&
                            item?.marketName ==
                              betSlipObject?.item?.marketName &&
                            showBetDialog
                          ? "back-active"
                          : "";

                        let conditionPosition =
                          showBetDialog &&
                          betSlipObject?.playType == "sportBook" &&
                          item?.id == selected &&
                          Number(betSlipObject?.bidPrice) > 0
                            ? true
                            : false;
                        let oldObject = item?.oldSportsBookSelection?.find(
                          (obj) => {
                            return obj?.id == res?.id;
                          }
                        );
                        let conditionShowPositon = !isEmpty(
                          position?.find((res) => {
                            return (
                              res?.marketId ==
                              `${item?.betfairEventId}S${item?.id}`
                            );
                          })
                        );

                        return (
                          <SportBookInner
                            oldObject={oldObject}
                            conditionShowPositon={conditionShowPositon}
                            res={res}
                            handelChange={handelChange}
                            item={item}
                            index={index}
                            parly={parly}
                            activeClass={activeClass}
                            newPosition={newPosition}
                            conditionPosition={conditionPosition}
                            setSelected={setSelected}
                            selectSpark={selectSpark}
                          />
                        );
                      })}
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        }
        // }
      })}
    </div>
  );
};

export default SportBookMatchInner;
