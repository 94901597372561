import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { RxCross2 } from "react-icons/rx";
import { Fade } from "react-awesome-reveal";
import { isEmpty } from "lodash";
const GameLayout = ({ casinoUrlHome, setCasinoUrlHome }) => {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setCasinoUrlHome("");
      }}
    >
      <div
        className={`market-depth-modal-casino bg-black d-flex flex-column justify-content-end align-items-center w-100 p-0 ${
          !isEmpty(casinoUrlHome) ? "active-model" : ""
        }`}
      >
        {!isEmpty(casinoUrlHome) && (
          <>
            <div className="d-flex justify-content-end align-items-center w-100 bg-black p-1 w-100">
              <RxCross2
                onClick={() => setCasinoUrlHome("")}
                size={25}
                color="white"
              />
            </div>
            <iframe
              style={{ height: "calc(100vh - 27px)" }}
              className="w-100"
              src={casinoUrlHome}
            />{" "}
          </>
        )}
      </div>{" "}
    </OutsideClickHandler>
  );
};

export default GameLayout;
