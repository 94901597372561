import React from "react";

const Loader = () => {
  return (
    <div id="loading" className="loading">
      <div className="loading-box">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

export default Loader;
